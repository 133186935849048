<script>
import ReturnsForm from './ReturnsForm'

export default {
  extends: ReturnsForm,

  data() {
    return {
      isNew: true
    }
  },

  mounted() {
    this.handleNetworkInput()
  },

  methods: {
    onSubmit() {
      this.createReturn(this.form)
        .then(() => {
          this.successSubmit({ message: 'Заявка успешно создана', routeName: 'returns' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
